import logo from "../img/logosmall.png";
import UserCard from "../components/UserCard";
import { useContext, useEffect, useState } from "react";
import { PrimaryContext } from "../components/MainContext";
import { useNavigate } from "react-router-dom";
import { successToast } from "../components/ToastAlerts";
import smile from "./smile.png";
import { WebSocketContext } from "../components/WebSoketContext";

const InnerUserPage = () => {
  const navigate = useNavigate();

  //context data
  const { packs, packCount, userData, setUserData } =
    useContext(PrimaryContext);
  const { socket } = useContext(WebSocketContext);
  //popup
  const [popup, setPopup] = useState(false);

  useEffect(() => {
    if (packCount === 0) {
      navigate("../");
    }
  }, []);

  //user trigger
  useEffect(() => {
    if (socket && userData?.id) {
      socket.on(`checkOutOrder/${userData?.id}`, (data) => {
        setUserData({});
        successToast("ამანათები გატანილია წარმატებით!");
        setPopup(true);
        setTimeout(() => {
          setPopup(false);
          navigate("../");
        }, 3000);
      });
    }
    return () => {
      if (socket && userData?.id) {
        socket.off(`checkOutOrder/${userData?.id}`);
      }
    };
  }, [socket, userData]);

  return (
    <div className="p-5">
      {popup && (
        <div
          className="fixed top-0 left-0 z-[60] w-screen h-screen bg-black
            bg-opacity-80 flex items-center justify-center"
        >
          <div className="bg-white rounded-lg p-10 flex flex-col items-center justify-center gap-5">
            <div
              className={`rounded-full w-20 h-20 border-4  p-4 text-[#5856d6]`}
            >
              <img src={smile} className={`w-full h-full text-[#5856d6]`} />
            </div>
            <div>
              <p className="text-xl text-center text-[#5856d6]">
                მადლობა მობრძანებისთვის !
              </p>
            </div>
          </div>
        </div>
      )}
      <div className="w-full border-b border-slate-300 flex items-center justify-between pb-3">
        <p className="font-bold text-slate-600 text-sm">
          {userData.id !== undefined && (
            <>
              {userData.fname_eng !== null && userData.fname_eng}{" "}
              {userData.lname_eng !== null && userData.lname_eng}
              {userData.cname_eng !== null && userData.cname_eng}{" "}
              {userData.id !== null && `${userData.id}`}
            </>
          )}
        </p>
        <img src={logo} className="h-10" />
      </div>
      <div className="mt-5 w-full">
        <div className="w-full flex items-center justify-between">
          <p className="font-bold text-slate-600 text-sm">
            ამანათები
            <span className="text-md text-green-500"> ({packCount})</span>
          </p>
        </div>

        <div className="grid grid-cols-1 gap-3 mt-5">
          {packs &&
            packs.map((item) => {
              return <UserCard key={item.id} item={item} />;
            })}
        </div>
      </div>
    </div>
  );
};

export default InnerUserPage;
