import logo from "../img/logosmall.png";
import UserCard from "../components/UserCard";
import { ToastContainer } from "react-toastify";
import { useContext, useEffect, useState } from "react";
import { PrimaryContext } from "../components/MainContext";
import notificationsound from "../img/notification.wav";
import { useRef } from "react";
import { WebSocketContext } from "../components/WebSoketContext";
import axios from "axios";
import { errorToast } from "../components/ToastAlerts";

const AdminPage = () => {
  //context data
  const { handleGivePack, handleGiveCheckedPacks } = useContext(PrimaryContext);
  const { socket } = useContext(WebSocketContext);
  //pack data
  const [packs, setPacks] = useState([]);
  //user
  const [userData, setUserData] = useState({});
  //checked packages
  const [checkedPackages, setCheckedPackages] = useState([]);
  // Create a ref for the audio context
  const audioContextRef = useRef(null);

  const [planShetId, setPlanShetId] = useState(localStorage.getItem("USER"));

  // Function to play audio
  const playAudio = async () => {
    if (!audioContextRef.current) {
      audioContextRef.current = new (window.AudioContext ||
        window.webkitAudioContext)();
    }

    const audioContext = audioContextRef.current;

    // Load and play the audio
    try {
      const response = await fetch(notificationsound);
      const audioData = await response.arrayBuffer();
      const audioBuffer = await audioContext.decodeAudioData(audioData);

      const source = audioContext.createBufferSource();
      source.buffer = audioBuffer;
      source.connect(audioContext.destination);
      source.start();
    } catch (error) {}
  };

  const handleGetParcels = (userId) => {
    if (userId && planShetId && planShetId !== 0) {
      axios
        .get(`https://back.mglparcel.com/api/trigg/${planShetId}/${userId}`)
        .then(({ data }) => {
          var customClarence = false;
          var tmp = data.packs.map((item) => {
            if (item.border_declar !== 0 && item.border_declar !== 2) {
              customClarence = true;
            }
            return item;
          });

          if (data.count === 0 && data.unpeydPacksCount === 0) {
            errorToast("თქვენ არ გაქვთ ჩამოსული ამანათები!");
          } else if (data.unpeydPacksCount !== 0) {
            errorToast("პირველ რიგში გთხოვთ დაფაროთ ტრანსპორტირების საფასური!");
          } else if (customClarence) {
            errorToast(
              "თქვენი ამანათი განსაბაჟებელია და გატანას შეძლებთ დეკლარაციის დაბეჭდვის შემდეგ!"
            );
          } else {
            setPacks(data.packs);
            //   setPackCount(data.count);
            setUserData(data.user);
            if (data.packs.length > 0) {
              playAudio();
            }
          }
        })
        .catch((err) => {});
    }
  };

  //admin trigger
  useEffect(() => {
    if (socket && planShetId && planShetId !== 0) {
      socket.on(`getOrder/${planShetId}`, (code) => {
        handleGetParcels(code);
      });
    }
    return () => {
      if (socket && planShetId && planShetId !== 0) {
        socket.off(`getOrder/${planShetId}`);
      }
    };
  }, [socket, planShetId]);

  return (
    <div className="p-5">
      <div className="w-full border-b border-slate-300 flex items-center justify-between pb-3">
        <p className="font-bold text-slate-600 text-sm">
          {userData.id !== undefined && (
            <>
              {userData.fname_eng !== null && userData.fname_eng}{" "}
              {userData.lname_eng !== null && userData.lname_eng}
              {userData.cname_eng !== null && userData.cname_eng}{" "}
              {userData.id !== null && `${userData.id}`}
            </>
          )}
        </p>
        <img src={logo} className="h-10" />
      </div>
      <div className="mt-5">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <p className="font-bold text-slate-600 text-sm">ამანათები</p>
            <p className="font-bold text-blue-600 text-sm">
              სულ: {packs?.length} ამანათი
            </p>
          </div>
          <div className="flex items-center gap-2">
            <button
              className="bg-green-500 tet-white text-sm text-white font-bold p-2
                    rounded-lg"
              onClick={() => {
                handleGiveCheckedPacks(checkedPackages, setPacks);
                setCheckedPackages([]);
              }}
            >
              მონიშნული ამანათები გაიტანეს
            </button>
            <button
              className="bg-green-500 tet-white text-sm text-white font-bold p-2
                    rounded-lg"
              onClick={() => {
                handleGivePack(packs, setPacks);
                setPacks([]);
              }}
            >
              ამანათები გაიტანეს
            </button>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-3 mt-5">
          {packs &&
            packs.map((item) => {
              return (
                <UserCard
                  key={item.id}
                  item={item}
                  admin={true}
                  setCheckedPackages={setCheckedPackages}
                  checkedPackages={checkedPackages}
                />
              );
            })}
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default AdminPage;
